import React, { useState } from "react";
import styled from "@emotion/styled";

import { COLORS } from "../ui/variables";
import ChevronArrowDownIco from "../images/icons/chevron-down-orange.svg";

const Container = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
`;

const Question = styled.div`
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  font-size: 22px;
  line-height: 24px;
  padding: 16px 32px 16px 0;
  cursor: pointer;
  background: url(${ChevronArrowDownIco}) no-repeat right center;
`;

const Answer = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? "block" : "none")};
  padding: 20px 0;
`;

export interface FAQItem {
  question: string;
  answer: string | JSX.Element;
}

const FAQAccordion = ({ items, className }: { items: FAQItem[], className?: string }) => {
  const [displayQuestion, setDisplayQuestion] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    if (displayQuestion === index) {
      setDisplayQuestion(null);
      return;
    }
    setDisplayQuestion(index);
  };

  return (
    <Container className={className}>
      {items.map((item, index) => (
        <li key={item.question}>
          <Question onClick={() => toggleQuestion(index)}>
            {item.question}
          </Question>
          <Answer active={displayQuestion === index}>{item.answer}</Answer>
        </li>
      ))}
    </Container>
  );
};

export default FAQAccordion;
